<template>
  <v-container fluid fill-height class="portfolio-container">
    <v-row class="text-left">

      <v-col cols="12">
        <h1 class="display-2 font-weight-bold mb-3">
          YOUTUBE @glens-games
        </h1>

        <h3 class="subheading font-weight-regular">
          Sometimes in life you end up trying something at random, and it ends up being bigger than you thought it could be. Back in 2021, since most of us were working from home, I started watching twitch streams which were exploding in popularity due to the pandemic. I joined a community of like-minded players and had a good time there. The streamer I was watching at the time picked up a little game known as Valheim that I had played previously. I made a short video just to share what combat looks like, and it just seemed to grow from there.
        </h3>
      </v-col>

      <v-timeline dense>
        <v-timeline-item right>
          <v-card class="elevation-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">VALHEIM - BOSS FIGHTS</v-list-item-title>
                <div>
                  My first 3 videos were incredibly straight forward. Since a lot of the people in the gaming community I was involved with started playing Valheim for the first time, I thought I'd share a couple videos on how to defeat some of the strongest bosses at the time: Bonemass, Moder and Yagluth. Initially after sharing, they had 50 views. I thought this was great! The community was watching them. Then they grew to the hundreds, then thousands, then tens of thousands. It was a fun experience, and I learned what Youtube was all about. <br><br>
                  Keep in mind, these videos were as simple as you get. A full uncut recording of a boss fight with a couple subtitles at the beginning. Nothing more, nothing less. As of July 2024, the Yagluth boss fight video has amassed over 30,000 views. I think people actually enjoyed the simplicity of the video since they could watch a full fight from start to finish without any "youtube clutter" that content creators add.<br><br>
                  <v-btn small color="primary" href="https://store.steampowered.com/app/892970/Valheim/" target="_blank">Valheim</v-btn>                  
                </div>
              </v-list-item-content>
              <v-img contain max-width="200" max-height="150" src="@/assets/helikon/AthanorSpaceS.jpg"></v-img>
            </v-list-item>
            <br/>
          </v-card>
        </v-timeline-item>
      </v-timeline>

      <v-timeline dense>
        <v-timeline-item right>
          <v-card class="elevation-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">THE RIFTBREAKER</v-list-item-title>
                <div>
                  Riftbreaker was a game that I was a huge fan of when it first came out. I followed their development for about a year before release, so I was very excited for this game. This is where I made my first video that ever "exploded". It was also when I decided to start adding voice-overs to my videos to try to make them more interesting.<br><br>
                  In Riftbreaker, you have to set up multiple bases on multiple locations throughout the world to gather all the different kinds of resources. However, if you used your scanner on the planet's flora, then build cultivators and harvesting stations, you can generate various resources over time. If you go heavy enough into this tech, you don't need to manually farm resources anymore, since the actual farm you set up will generate enough for you.<br><br>
                  I made this video 10 days after the game's official release. It's also titled "Get Unlimited Resources with Farming!". It's this combination that tought me that if you want to make a succesful video on Youtube, you need to make something that people will actually search for. "How do I get more resources?" might be a common question for people playing the game, and it caused this video to explode overnight. In the first couple of days, it immediately got almost 10,000 views. It really shows that if you can come up with interesting content days after a game's released using keywords people are using, you're going to get a lot of views.<br><br>
                  <v-btn small color="primary" href="https://store.steampowered.com/app/780310/The_Riftbreaker/" target="_blank">The Riftbreaker</v-btn>                  
                </div>
              </v-list-item-content>
              <v-img contain max-width="200" max-height="150" src="@/assets/helikon/AthanorSpaceS.jpg"></v-img>
            </v-list-item>
            <br/>
          </v-card>
        </v-timeline-item>
      </v-timeline>

      <v-timeline dense>
        <v-timeline-item right>
          <v-card class="elevation-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">DYSON SPHERE PROGRAM</v-list-item-title>
                <div>
                  At this time, I've been playing Dyson Sphere Program for a while. Every time a patch was released, all sorts of new mechanics were usually added to the game. This time however, they decided to add achievements to the game. Getting achievements in a factory game can usually be quite complex. Since this was a fairly niche game, I decided to dedicate a series to getting 100% of the game's achievements.<br><br>
                  Achievement videos aren't anything new, and it's always tricky to come up with a good design if you want to make videos about them. You could make 1 video with every achievement, but that could take forever. Looking back, getting 100% of the achievements was fine, but some of the more tricky achievements should've been their own short videos. The 1st video called "One Giant Leap for Mankind!" is a good example since the video itself is about 1 difficult achievement, but it includes other easier achievements that you get along the way. Also, people just enjoy "speed run" style videos anyway.<br><br>
                  <v-btn small color="primary" href="https://store.steampowered.com/app/1366540/Dyson_Sphere_Program/" target="_blank">The Riftbreaker</v-btn>                  
                </div>
              </v-list-item-content>
              <v-img contain max-width="200" max-height="150" src="@/assets/helikon/AthanorSpaceS.jpg"></v-img>
            </v-list-item>
            <br/>
          </v-card>
        </v-timeline-item>
      </v-timeline>

      <v-timeline dense>
        <v-timeline-item right>
          <v-card class="elevation-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">V RISING</v-list-item-title>
                <div>
                  I love playing V Rising. It's an incredibly fun game that you can either speed run through in a couple days, or take your time over several weeks. You can play either PvE by yourself, or make things interesting and play on a PvP server.<br><br>
                  <v-btn small color="primary" href="https://store.steampowered.com/app/1366540/Dyson_Sphere_Program/" target="_blank">The Riftbreaker</v-btn>                  
                </div>
              </v-list-item-content>
              <v-img contain max-width="200" max-height="150" src="@/assets/helikon/AthanorSpaceS.jpg"></v-img>
            </v-list-item>
            <br/>
          </v-card>
        </v-timeline-item>
      </v-timeline>

      <v-timeline dense>
        <v-timeline-item right>
          <v-card class="elevation-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">GROUNDED</v-list-item-title>
                <div>
                  I played Grounded in early access before.<br><br>
                  <v-btn small color="primary" href="https://store.steampowered.com/app/1366540/Dyson_Sphere_Program/" target="_blank">The Riftbreaker</v-btn>                  
                </div>
              </v-list-item-content>
              <v-img contain max-width="200" max-height="150" src="@/assets/helikon/AthanorSpaceS.jpg"></v-img>
            </v-list-item>
            <br/>
          </v-card>
        </v-timeline-item>
      </v-timeline>

      <v-timeline dense>
        <v-timeline-item right>
          <v-card class="elevation-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">VALHEIM - BOSS FIGHTS #2</v-list-item-title>
                <div>
                  After a long wait, Valheim finally released a new patch with a new biome called the Mistlands. Since it was the first game that brought my youtube channel to life, I figured I'd do another video series about it. However, with what I learned, I didn't think just re-recording the boss fights would be that interesting.<br><br>
                  Then one day, it hit me. Valheim is a pretty open-world game where people can come up with some creative ways to defeat bosses. There were lots of different "how to kill xxx" video guides, but I couldn't find any comprehensive ones that included them all. That's when I came up with the idea of the "X ways to kill Y" boss series.<br><br>
                  It started off pretty simple, and the initial videos did fairly well with a few thousand views here and there. However, once you got to the bosses that mattered, the views exploded more than they ever had. As of July 2024, the most popular video on my channel "7 ways to kill Yagluth" now has 160,000 views, with "5 ways to kill the Queen" right behind it with 140,000 views. It was a fun video series to work on since it challenged my creativity, and I was really proud of the eye-catching thumbnails I managed to create for it. I think it'll be a long time before I ever make anything as popular as that, but it was definitely a fun project I enjoyed making.<br><br>
                  <v-btn small color="primary" href="https://store.steampowered.com/app/1366540/Dyson_Sphere_Program/" target="_blank">The Riftbreaker</v-btn>                  
                </div>
              </v-list-item-content>
              <v-img contain max-width="200" max-height="150" src="@/assets/helikon/AthanorSpaceS.jpg"></v-img>
            </v-list-item>
            <br/>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-row>

    <v-dialog v-if="thumbnailDialog" v-model="thumbnailDialog" max-width="1200">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ thumbnailsTitle }}
        </v-card-title>

        <v-card-text>
          <v-carousel show-arrows-on-hover hide-delimiters height="850">
            <v-carousel-item v-for="(thumb, i) in thumbnails" :key="i">
              <v-img contain aspect-ratio="1" :src="thumb" max-height="850"></v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="thumbnailDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="dialog" v-model="dialog" max-width="800">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Ginger Snaps!
        </v-card-title>

        <v-card-text>
          <vue-core-video-player src="http://classicalgamer.com/video/2010_shrek_360.mp4"></vue-core-video-player>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      dialog: false,
      thumbnailDialog: false,
      thumbnails: null,
      thumbnailsMediaVault: [ require('@/assets/disney/mediavault_opentext.jpg'), require('@/assets/disney/mediaportal_search.jpg'), require('@/assets/disney/mediaportal_preview.jpg'), require('@/assets/disney/mediaportal_uploads.jpg'), require('@/assets/disney/mediaportal_upload_complete.jpg'), ],
      thumbnailsInfinity: [ require('@/assets/disney/infinity_st_all_versions.jpg'), require('@/assets/disney/infinity_st_all_platforms.jpg'), require('@/assets/disney/infinity_horcrux.jpg'), ],
      thumbnailsCap: [ require('@/assets/disney/cap_world.jpg'), require('@/assets/disney/cap_tagging.jpg'), require('@/assets/disney/cap_export.jpg'), require('@/assets/disney/cap_preview.jpg'), ],
      thumbnailsMetaplace: [ require('@/assets/disney/metaplace_portal.jpg'), require('@/assets/disney/metaplace_start.jpg'), require('@/assets/disney/metaplace_tool.jpg'), require('@/assets/disney/metaplace_lua.jpg'), require('@/assets/disney/metaplace_myvineyard.jpg'), require('@/assets/disney/metaplace_deeprealms.jpg'), require('@/assets/disney/metaplace_gnometown.jpg'), ],
    }),

    methods: {
      openThumbnails(project) {
        switch (project) {
          case 'mediavault':
            this.thumbnailsTitle = 'Media Vault';
            this.thumbnails = this.thumbnailsMediaVault;
            break;
          case 'infinity':
            this.thumbnailsTitle = 'Disney Infinity';
            this.thumbnails = this.thumbnailsInfinity;
            break;
          case 'cap':
            this.thumbnailsTitle = 'Club Penguin - Centralized Asset Pipeline';
            this.thumbnails = this.thumbnailsCap;
            break;
          case 'metaplace':
            this.thumbnailsTitle = 'Metaplace';
            this.thumbnails = this.thumbnailsMetaplace;
            break;
        }
        this.thumbnailDialog = true;
      }
    },
  }
</script>

<style type="text/css" scoped>
.portfolio-container {
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  background: #fff;
}

.tech-spacing > a {
  margin: 5px;
}
</style>
