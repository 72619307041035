<template>
  <v-container fluid fill-height class="portfolio-container">
    <v-row class="text-left">

      <v-col cols="12">
        <h1 class="display-2 font-weight-bold mb-3">
          DISNEY / FLEXFORCE
        </h1>

        <h3 class="subheading font-weight-regular">
          Getting to work for Disney was an incredible experience. Before this I lived in Ottawa which is known for being incredibly cold in the winter and incredibly hot in the summer. That all changes when I got to live in another country in a city that's sunny 99% of the time. I also got lots of "Disney perks" where I could go to Disneyland & Disneyworld for free, and I got to work for a well known world-class company.<p/>
          When I first started, I worked for a small studio recently acquired by Disney called Metaplace. Metaplace was basically a tool that allowed you to make MMO-style games fast & easy. My job was to enhance the tool so that teams making the games would have an easier time.<p/>
          After a few years, Disney moved away from making games in-house and decided to license them out to other studios instead. This meant our office had to shift our priorities if we wanted to keep working. Thus was born <b>Flex-Force</b>. A small studio of about 20 employees that could act as in-house contractors working for any division within Disney if a team needed a senior-level engineer quickly.
        </h3>
      </v-col>

      <v-timeline dense>
        <v-timeline-item right>
          <v-card class="elevation-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">RDX - Robot Data Exchange</v-list-item-title>
                <v-list-item-subtitle>2020</v-list-item-subtitle>
                <div>
                  Managing how robots work at Disney attractions is very complicated. Each robot has multiple parts, and each part might have multiple brands of motors which interface with multiple gears, which require their limits and settings to be known to artists so they can properly animate, etc...<br/><br/>
                  This project wasn't so much about directly controlling the robots, but managing all that data in a single place. Believe it or not, most of that data existed in a large spreadsheet that was just passed around to different teams within the company. This makes it easy to cause problems because someone could easily delete/edit a row in the database, and you'd have no idea who or when that happened. By moving all this functionality to a website with a server backend, you gain a lot of control over all that data. In summary, this was the largest spreadsheet I ever had to work on.<br/><br/>
                  <v-list-item-subtitle>Technologies Used:</v-list-item-subtitle>
                  <div class="tech-spacing">
                    <v-btn small color="primary" href="https://vuejs.org/" target="_blank">Vue</v-btn>
                    <v-btn small color="primary" href="https://vuetifyjs.com/en/" target="_blank">Vuetify</v-btn>
                    <v-btn small color="primary" href="https://nestjs.com/" target="_blank">NestJS</v-btn>
                    <v-btn small color="primary" href="https://nodejs.org/en/" target="_blank">NodeJS</v-btn>
                    <v-btn small color="primary" href="https://www.typescriptlang.org/" target="_blank">Typescript</v-btn>
                    <v-btn small color="primary" href="https://typeorm.io/#/" target="_blank">TypeORM</v-btn>
                    <v-btn small color="primary" href="https://www.postgresql.org/" target="_blank">PostGres</v-btn>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
            <br/>
          </v-card>
        </v-timeline-item>

        <v-timeline-item right>
          <v-card class="elevation-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">Peep</v-list-item-title>
                <v-list-item-subtitle>2019</v-list-item-subtitle>
                <div>
                  I can't go into the specifics on what this project does, but essentially I had to write an app that could easily scale to thousands of instances if need be. There could be N apps running collecting data, passing it to N apps that process the data, while also having N apps hosting web-endpoints for other apps to interface with this project. I was moved off this project halfway, but I did get a chance to play with all the technologies involved and it was pretty neat to be able to build an app and just go N=1000 (even while it was running) and it would work seamlessly.<br/><br/>
                  <v-list-item-subtitle>Technologies Used:</v-list-item-subtitle>
                  <div class="tech-spacing">
                    <v-btn small color="primary" href="https://nestjs.com/" target="_blank">NestJS</v-btn>
                    <v-btn small color="primary" href="https://www.rabbitmq.com/" target="_blank">RabbitMQ</v-btn>
                    <v-btn small color="primary" href="https://containo.us/traefik/" target="_blank">Traefik</v-btn>
                    <v-btn small color="primary" href="https://www.postgresql.org/" target="_blank">PostGres</v-btn>
                    <v-btn small color="primary" href="https://www.docker.com/" target="_blank">Docker</v-btn>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
            <br/>
          </v-card>
        </v-timeline-item>

        <v-timeline-item right>
          <v-card class="elevation-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">MediaVault</v-list-item-title>
                <v-list-item-subtitle>2017-2019</v-list-item-subtitle>
                <div>
                  This was actually an interesting project where I got the first chance to work as a full-stack engineer. Just take a look below to see how many technologies were involved while developing this project.<br/><br/>
                  The purpose of the project was to make Disney's internal digital archive better. There's a product you can buy called <a href="https://www.opentext.com/" target="_blank">OpenText</a> which is advertised as an archive suite. The only issue is that it's incredibly slow very unreliable. When I first started on the project, users could only upload no more than 10 assets per day. This was largely due to the fact that the user's browser did most of the work. When we finished creating a new wrapper to the site called MediaPortal that interfaced with MediaVault on behalf of our users, the number of uploads per user went up to the thousands.<br/><br/>
                  One other feature of this project we got to use was an extremely thorough amount of unit and integration testing. Whenever someone would make a commit to Git, a CI job would run to check that the code builds, and that all tests passed. Code could not be merged into the main branch unless all tests passed. While this did add time to development, it was very reassuring to know that when you made a code change you didn't break something else. There were almost a dozen occasions where we were thankful to have these thorough tests running.<br/><br/>
                  <v-list-item-subtitle>Technologies Used:</v-list-item-subtitle>
                  <div class="tech-spacing">
                    <v-btn small color="primary" href="https://vuejs.org/" target="_blank">Vue</v-btn>
                    <v-btn small color="primary" href="https://coffeescript.org/" target="_blank">CoffeeScript</v-btn>
                    <v-btn small color="primary" href="https://www.mongodb.com/" target="_blank">MongoDB</v-btn>
                    <v-btn small color="primary" href="https://www.meteor.com/" target="_blank">Meteor</v-btn>
                    <v-btn small color="primary" href="https://www.opentext.com/" target="_blank">OpenText</v-btn>
                    <v-btn small color="primary" href="https://www.postgresql.org/" target="_blank">PostGres</v-btn>
                    <v-btn small color="primary" href="https://www.docker.com/" target="_blank">Docker</v-btn>
                    <v-btn small color="primary" href="https://www.java.com/en/" target="_blank">Java</v-btn>
                    <v-btn small color="primary" href="https://spring.io/projects/spring-framework" target="_blank">Java Spring Framework</v-btn>
                    <v-btn small color="primary" href="https://webdriver.io/" target="_blank">WebDriver IO</v-btn>
                    <v-btn small color="primary" href="https://devexpress.github.io/testcafe/" target="_blank">TestCafe</v-btn>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-container style="max-width: 1370px">
              <v-card flat tile class="d-flex" elevation="4" max-width="1350" @click="openThumbnails('mediavault')">
                <v-img v-for="(thumb, i) in thumbnailsMediaVault" :key="i" aspect-ratio="1" :src="thumb" style="margin: 10px"></v-img>
              </v-card>
            </v-container>
            <br/>
          </v-card>
        </v-timeline-item>

        <v-timeline-item right>
          <v-card class="elevation-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">Disney Playmation - Star Wars</v-list-item-title>
                <v-list-item-subtitle>2016</v-list-item-subtitle>
                <div>
                  This project unfortunately never launched. If you search for Disney Playmation you'll find a pretty ingenious toy where kids could play using an IronMan glove. Disney had planned to create a 2nd iteration of this using the light saber, but unfortunately the tech would just be too expensive to hit the mass market. For those wondering, if you produce a toy and it costs $20 worth of materials to build, there's a 5x markup by the time it hits the store shelves. Not everyone wants to spend over $100 for a single toy for their kids.<br/><br/>
                  My contribution to this project was to allow the audio directors to edit audio levels of all the sounds played. This includes the effects that would play from the toys, the saber, and the headset. It was created using Apple's Swift language as the tool would be primarily used on OSX platforms.<br/><br/>
                  <v-list-item-subtitle>Technologies Used:</v-list-item-subtitle>
                  <div class="tech-spacing">
                    <v-btn small color="primary" href="https://developer.apple.com/swift/" target="_blank">Swift</v-btn>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
            <br/>
          </v-card>
        </v-timeline-item>

        <v-timeline-item right>
          <v-card class="elevation-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">Disney Infinity</v-list-item-title>
                <v-list-item-subtitle>2015</v-list-item-subtitle>
                <div>
                  Disney Infinity was a pretty large project. For those that don't know, the owners of the different Disney IPs are very protective of their brand. The fact that they got almost all the IPs from Marvel, Cars, Toy Story, Alladin, Frozen, Star Wars, etc... to all exist in the same universe was a feat in itself.<br/><br/>
                  My contribution to this project was 2 internal tools to help with QA and Project Management. The first was a product called <b>Session Tracker</b>. While the servers were already collecting data on users that used the multiplayer feature, it had a real bad user interface that only a couple people could barely understand. My job was to make it a lot more visual so you could see how many players, where & when, were playing those games.<br/><br/>
                  The second product was called <b>Horcrux</b>. Again, my job was mostly to improve on an already existing product. Horcrux was a system that would upload the auto-saves from a QA tester to a server. When QA would report a bug, their save file would go on a server where developers could easily download and replay their exact steps with all the same data QA was using.<br/><br/>
                  <v-list-item-subtitle>Technologies Used:</v-list-item-subtitle>
                  <div class="tech-spacing">
                    <v-btn small color="primary" href="https://www.mongodb.com/" target="_blank">MongoDB</v-btn>
                    <v-btn small color="primary" href="https://redis.io/" target="_blank">Redis</v-btn>
                    <v-btn small color="primary" href="https://www.java.com/en/" target="_blank">Java</v-btn>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-container style="max-width: 830px">
              <v-card flat tile class="d-flex" elevation="4" max-width="810" @click="openThumbnails('infinity')">
                <v-img v-for="(thumb, i) in thumbnailsInfinity" :key="i" aspect-ratio="1" :src="thumb" style="margin: 10px"></v-img>
              </v-card>
            </v-container>
            <br/>
          </v-card>
        </v-timeline-item>

        <v-timeline-item right>
          <v-card class="elevation-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">Club Penguin - Centralized Asset Pipeline</v-list-item-title>
                <v-list-item-subtitle>2014</v-list-item-subtitle>
                <div>
                  A flash games were slowly coming to an end, Club Penguin needed to reinvent themselves to stay alive. A pretty ambitious project was to basically convert this large MMO-flash-web game to something that could work on mobile devices.<br/><br/>
                  When working with MMOs, you quickly realize that the longer your games is out, the more assets you have. Converting those assets can be quite difficult, especially when you have to build assets to work with specific mobile devices (An image's resolution and compression required for an Andriod are different from iOS). This is where CAP (Centralized Asset Pipeline) comes in. Artists would continue to work in Flash Developer like they always have, but a new tool was added where they had to tag their assets. Once tagged, they simply had to export those assets to our servers where they would be processed and converted to all the required formats.<br/><br/>
                  Believe it or not, one of the more fun aspects of working on this project was the amount of analytics we were using. If you ever work in the software tools industry, know that there will come a time where users will use a work-around instead of reporting a bug. With analytics, we were reporting bugs ourselves when users ran into them, and when users ran into problems, we would know the exact version they were running. We also could track when users were using our tool the most (usually at the end of the day on Friday).<br/><br/>
                  <v-list-item-subtitle>Technologies Used:</v-list-item-subtitle>
                  <div class="tech-spacing">
                    <v-btn small color="primary" href="https://nodejs.org/en/" target="_blank">NodeJS</v-btn>
                    <v-btn small color="primary" href="https://www.docker.com/" target="_blank">Docker</v-btn>
                    <v-btn small color="primary" href="https://www.mongodb.com/" target="_blank">MongoDB</v-btn>
                    <v-btn small color="accent" href="https://en.wikipedia.org/wiki/ActionScript" target="_blank">Flash Actionscript</v-btn>
                    <v-btn small color="accent" href="https://www.adobe.com/products/flex.html" target="_blank">Adobe Flex Framework</v-btn>
                    <v-btn small color="accent" href="https://help.adobe.com/archive/en_US/flash/cs5/flash_cs5_extending.pdf" target="_blank">JSFL</v-btn>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-container style="max-width: 830px">
              <v-card flat tile class="d-flex" elevation="4" max-width="810" @click="openThumbnails('cap')">
                <v-img v-for="(thumb, i) in thumbnailsCap" :key="i" aspect-ratio="1" :src="thumb" style="margin: 10px"></v-img>
              </v-card>
            </v-container>
            <br/>
          </v-card>
        </v-timeline-item>

        <v-timeline-item right>
          <v-card class="elevation-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">Metaplace</v-list-item-title>
                <v-list-item-subtitle>2010 - 2013</v-list-item-subtitle>
                <div>
                The main selling point behind all the acquisions at this company was for the product called Metaplace. If you already had some prior experience with this product, you could effectively create a FarmVille clone prototype within a week. This would include multiplayer capability, isometric worlds, player interactivity, and world creation.<br/><br/>
                In the world of Facebook Flash games, they came and go pretty quickly. To have an engine that could make games fast was a great benefit because you'd spend less time on development, and more time getting your product to launch. Some words I remember from our CEO John Donham was "Fail Fast". If you're product doesn't work, it's better that you spent a couple months on it rather than a couple years.<br/><br/>
                Some of the games I remember helping with were My Vineyard, Deep Realms, Gnome Town, Dragon Town, and Secret Agent X. The latter two never really made it, but SAX has it's own story I like to tell. In Metaplace, your game scripts are written in Lua. The developers of SAX hated Lua so much that they wrote Javascript-like wrappers so they could code the game how they wanted to. This basically means they wrote 1000+ lines of "Fake Javascript" code to do what you could easily do in 1-2 lines of Lua. When an instance of a Metaplace game comes online, a dedicated server runs as a host for the player. If the code is written well, 500-1000 players can run off of a single machine (This was the case for Club Penguin). When this product started testing, a single machine could only host 5-10 players. Since fixing this would require the game to be rewritten from scratch, unfortunately the whole project was scrapped. It had a pretty cool concept though! The player played as a mild-mannered woman living in a house by herself in a quiet neighborhood. You spend your daily energy doing tasks for the community (cleaning, shopping, etc...). These tasks would build your "Cover" energy meter, which would then allow you to go on secret spy missions. How cool is that!?<br/><br/>
                  <v-list-item-subtitle>Technologies Used:</v-list-item-subtitle>
                  <div class="tech-spacing">
                    <v-btn small color="accent" href="https://en.wikipedia.org/wiki/ActionScript" target="_blank">Flash Actionscript</v-btn>
                    <v-btn small color="accent" href="https://www.adobe.com/products/flex.html" target="_blank">Adobe Flex Framework</v-btn>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-container style="max-width: 1820px">
              <v-card flat tile class="d-flex" elevation="4" max-width="1800" @click="openThumbnails('metaplace')">
                <v-img v-for="(thumb, i) in thumbnailsMetaplace" :key="i" aspect-ratio="1" :src="thumb" style="margin: 10px"></v-img>
              </v-card>
            </v-container>
            <br/>
          </v-card>
        </v-timeline-item>

      </v-timeline>
    </v-row>

    <v-dialog v-if="thumbnailDialog" v-model="thumbnailDialog" max-width="1200">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ thumbnailsTitle }}
        </v-card-title>

        <v-card-text>
          <v-carousel show-arrows-on-hover hide-delimiters height="850">
            <v-carousel-item v-for="(thumb, i) in thumbnails" :key="i">
              <v-img contain aspect-ratio="1" :src="thumb" max-height="850"></v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="thumbnailDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="dialog" v-model="dialog" max-width="800">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Ginger Snaps!
        </v-card-title>

        <v-card-text>
          <vue-core-video-player src="http://classicalgamer.com/video/2010_shrek_360.mp4"></vue-core-video-player>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      dialog: false,
      thumbnailDialog: false,
      thumbnails: null,
      thumbnailsMediaVault: [ require('@/assets/disney/mediavault_opentext.jpg'), require('@/assets/disney/mediaportal_search.jpg'), require('@/assets/disney/mediaportal_preview.jpg'), require('@/assets/disney/mediaportal_uploads.jpg'), require('@/assets/disney/mediaportal_upload_complete.jpg'), ],
      thumbnailsInfinity: [ require('@/assets/disney/infinity_st_all_versions.jpg'), require('@/assets/disney/infinity_st_all_platforms.jpg'), require('@/assets/disney/infinity_horcrux.jpg'), ],
      thumbnailsCap: [ require('@/assets/disney/cap_world.jpg'), require('@/assets/disney/cap_tagging.jpg'), require('@/assets/disney/cap_export.jpg'), require('@/assets/disney/cap_preview.jpg'), ],
      thumbnailsMetaplace: [ require('@/assets/disney/metaplace_portal.jpg'), require('@/assets/disney/metaplace_start.jpg'), require('@/assets/disney/metaplace_tool.jpg'), require('@/assets/disney/metaplace_lua.jpg'), require('@/assets/disney/metaplace_myvineyard.jpg'), require('@/assets/disney/metaplace_deeprealms.jpg'), require('@/assets/disney/metaplace_gnometown.jpg'), ],
    }),

    methods: {
      openThumbnails(project) {
        switch (project) {
          case 'mediavault':
            this.thumbnailsTitle = 'Media Vault';
            this.thumbnails = this.thumbnailsMediaVault;
            break;
          case 'infinity':
            this.thumbnailsTitle = 'Disney Infinity';
            this.thumbnails = this.thumbnailsInfinity;
            break;
          case 'cap':
            this.thumbnailsTitle = 'Club Penguin - Centralized Asset Pipeline';
            this.thumbnails = this.thumbnailsCap;
            break;
          case 'metaplace':
            this.thumbnailsTitle = 'Metaplace';
            this.thumbnails = this.thumbnailsMetaplace;
            break;
        }
        this.thumbnailDialog = true;
      }
    },
  }
</script>

<style type="text/css" scoped>
.portfolio-container {
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  background: #fff;
}

.tech-spacing > a {
  margin: 5px;
}
</style>
