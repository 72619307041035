import { render, staticRenderFns } from "./portfolio-disney.vue?vue&type=template&id=6cda3367&scoped=true"
import script from "./portfolio-disney.vue?vue&type=script&lang=js"
export * from "./portfolio-disney.vue?vue&type=script&lang=js"
import style0 from "./portfolio-disney.vue?vue&type=style&index=0&id=6cda3367&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cda3367",
  null
  
)

export default component.exports