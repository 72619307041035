<template>
  <v-container fluid fill-height class="portfolio-container">
    <v-row class="text-left">

      <v-col cols="12">
        <h1 class="display-2 font-weight-bold mb-3">
          FUEL INDUSTRIES
        </h1>

        <h3 class="subheading font-weight-regular">
          Fuel Industries was a leading developer in flash-based adver-gaming content. It might sound a little wierd at first, but there are probably more adver-games out there than you realize. It's not farfetched that a company would build an ad around a video game. The game "Cool Spot" by 7up is a pretty good example of how far you can take this. You could even say that while a video ad can last 5-30 seconds, a game done well enough could potentially pull you in for hours.<p/>
          I worked on a lot of different projects while at Fuel Industries. They included puzzle games, action games, and even platformers. While the business was very challenging with it's short timelines and numerous projects, it was always a pleasure to work on so many different types of genres.<p/>
          While I could list out the large number of projects I worked on, instead I'm going to highlight some of my favorites
        </h3>
      </v-col>

      <v-timeline dense>
        <v-timeline-item right>
          <v-card class="elevation-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">Disrespectoids</v-list-item-title>
                <v-list-item-subtitle>2009-2010</v-list-item-subtitle>
                <div>
                  In the late 2000s, CapriSun's slogan started with "Respect the Pouch", but what if you disrespected it? We now introduce The Disrespectoids. A bunch of kids that were cruel to pouches of CapriSun and became horribly disfigured mutants... but in a fun way!<br/><br/>
                  In 2009, we created a few different minigames for their introduction. I worked on 4 of those games:
                  <ul>
                    <li>Bobblehead Fred</li>
                    <li>Boing Boing Betty</li>
                    <li>Chuck the Clucker</li>
                    <li>Handlebar Harry</li>
                  </ul><br/>
                  Bobblehead Fred had to dodge obstacles while avoiding the swarm of bees that was chasing him. If you took certain injuries, his animations would update and show while he was running.<br/>
                  Boing Boing Betty launches kids into the area of the playground of their choice. The angle and power used just played out an animation, but the tricky part was the arc shown while aiming. Flash was a 2D program, and to draw an arc that would fade towards the end while curving in such a way to look "3D" was pretty challenging.<br/>
                  Chuck the Clucker's game was pretty fun. It's sort of a tower defense shoot'em up where kids and squirrels try to take your pouches. You had to shoot eggs at them, and some kids had helmets which required multiple shots.<br/>
                  Handlebar Harry was a game that came out months later, but had cameos of all the previous characters. It was drawn in an isometric style, and I was heavily inspired by Blizzard's Rock'n'Roll Racing (SNES) while working on this game. When you start you're just racing other kids, but as levels progressed the other Disrespectoids joined in on the race. Chuck dropped eggs, Fred would just plow through obstacles, and Leaky Louie (another character) was a faster skater that would leave slippery puddles everywhere.<br/><br/>
                  One issue about making Flash games was that having a high frame rate was very difficult. In the case of Handlebar Harry, I wrote a physics engine that considered everything to be only circles or squares in order to calculate the game's logic quickly. Once that was in place, I wrote an optimized painter that would paint the isometric tiles and the characters in the correct Z order. As far as the painter was concerned, anything that wasn't in the main view didn't exist, and everything else that was was optimized in such a way that drawing the scene could be done as quickly as possible.<br/>
                  <v-container style="max-width: 1370px">
                    <v-card flat tile class="d-flex" elevation="4" max-width="1350" @click="openThumbnails('disrespectoids1')">
                      <v-img v-for="(thumb, i) in thumbnailsDisrespectoids1" :key="i" aspect-ratio="1" :src="thumb" style="margin: 10px"></v-img>
                    </v-card>
                  </v-container>
                  In 2010, CapriSun enjoyed this new IP enough that they wanted another set of games produced. This time, instead of making several individual games for each character, we opted to create one large game with different every playable character inside of it.<br/><br/>
                  There were 3 types of games to represent the different levels:
                  <ul>
                    <li>A very mario-like platformer. Collect pouches, and avoid or bouncing on enemies.</li>
                    <li>A tower-defense mini-game. Within the time limit, you have to knock out as many opponents as you can.</li>
                    <li>A 3D boss arena fight</li>
                  </ul><br/>
                  <v-container style="max-width: 1370px">
                    <v-card flat tile class="d-flex" elevation="4" max-width="1350" @click="openThumbnails('disrespectoids2')">
                      <v-img v-for="(thumb, i) in thumbnailsDisrespectoids2" :key="i" aspect-ratio="1" :src="thumb" style="margin: 10px"></v-img>
                    </v-card>
                  </v-container>
                  Making platformers and tower shooter games were pretty common in the days of Flash gaming, but a 3D boss arena was pretty unique for the time. It also took a lot of inginuity and creativity to pull it off. I ran through multiple prototypes before settling on a new Z-rotation feature that Adobe Flash had introduced pretty recently. The end results looked pretty amazing for a web game.
                </div>
              </v-list-item-content>
            </v-list-item>
            <br/>
          </v-card>
        </v-timeline-item>

        <v-timeline-item right>
          <v-card class="elevation-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">Shrek Happy Meal Board Game</v-list-item-title>
                <v-list-item-subtitle>2010</v-list-item-subtitle>
                Along with the release of Shrek 4, Fuel Industries partnered with McDonalds to promote the Shrek 4 Happy Meal.<br/><br/>
                The game was fairly large. The player moved across a board game, and landing on certain tiles would start a mini game (Mario Party Style). My contributions to the project as a whole was pretty small, but I did get to make an incredibly fun game called Ginger Snaps!<br/><br/>
                If you click on the thumbnail, you'll get to see a video recording I took it a long time ago.<br/>
              </v-list-item-content>
              <v-img contain max-width="200" max-height="150" src="@/assets/fuel/2010_shrek.jpg" @click="videoDialog=true"></v-img>
            </v-list-item>
          </v-card>
        </v-timeline-item>

      <v-timeline-item right>
        <v-card class="elevation-2">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline">GX Skate: Sick Tricks Game</v-list-item-title>
              <v-list-item-subtitle>2009</v-list-item-subtitle>
              It's all about SICK TRICKS and COOL FLIPS in this game (at least, that's what our client kept saying). GX Racers Scakeboards were these little toys that could propel themselves using gyros. You could swap out the gyros to make the toys do kick stands and rail grinds with other accessories that you buy with them.<br/>
              <a href="https://skateandannoy.com/blog/2009/09/gx-skate-gyro-thingy/" target="_blank">Here's a blog post that goes into more detail about them.</a><br/>
              What's fun about this project is that I was actually given a lot of creative control over it. With the privelege to make whichever game I wanted, and in order to make it a fun fast-paced adrenaline pumping game, I modeled it after the battletoads hoverbike level (you know the one!).<br/><br/>
              The gameplay is pretty simple. You control your skateboard and a range of obstacles will get in your way. While dodging trashcans, there's rail grinds and ramps for you to hit as you try to collect as many coins in a level as possible. If you reach the end, you enter a bonus stage where you try to collect as many coins as possible to reach the highest scores.<br/><br/>
              I knew that if anyone played this game that I would have to have a range of difficulties. "Easy" is for your common flash game enjoyer, but "Extreme!" is for people that wanted a real challenge. I was really proud of the work I put into this. Hopefully one day I'll remake something like this so everyone can enjoy it.<br/><br/>
            </v-list-item-content>
          </v-list-item>
          <v-container style="max-width: 840px">
            <v-card flat tile class="d-flex" elevation="4" max-width="1350" @click="openThumbnails('gxskate')">
              <v-img v-for="(thumb, i) in thumbnailsGXSkate" :key="i" aspect-ratio="1" :src="thumb" style="margin: 10px"></v-img>
            </v-card>
          </v-container>
          <br/>
        </v-card>
      </v-timeline-item>

      <v-timeline-item right>
        <v-card class="elevation-2">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline">Juku Couture - Dress Up</v-list-item-title>
              <v-list-item-subtitle>2009</v-list-item-subtitle>
              Juku Couture was a brand of dolls made by Jakks Pacific, a toy manufacturer. The dress up game is pretty simple, but in this game we gave our players a lot of options. You start off by picking one of the four dolls, then you customize a total of 10 different articles of clothing including hats, dresses, skirts, boots, etc... Each clothing type had various options, a choice of colors, and players could even paint decals on clothing to give them a custom look. Once you were done, you would take a photo of your character, then customize the background with colors and decals again. Lastly, you could save that final photo and/or upload it to the net where others could vote on their favorites. Since we gave our players so many options, there were some pretty interesting designs that people came up with.
            </v-list-item-content>
          </v-list-item>
          <v-container style="max-width: 720px">
            <v-card flat tile class="d-flex" elevation="4" max-width="1350" @click="openThumbnails('jukucouture')">
              <v-img v-for="(thumb, i) in thumbnailsJuku" :key="i" aspect-ratio="1" :src="thumb" style="margin: 10px"></v-img>
            </v-card>
          </v-container>
          <br/>
        </v-card>
      </v-timeline-item>

      <v-timeline-item right>
        <v-card class="elevation-2">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline">Club Shockwave: Beach Towel Bingo</v-list-item-title>
              <v-list-item-subtitle>2009</v-list-item-subtitle>
              In the old days, Candystand and Shockwave.com were among the few websites that hosted several flash game for people to play. One game I was tasked to create for Shockwave was a game called "Beach Towel Bingo". The concept was simple: You play a bingo game with a group of players, and your bingo card progress was shown at the top as little surfers trying to reach the finish line. The closer you are to a bingo, the farther your surfer was on the map.<br/><br/>
              The whole contract with Club Shockwave actually came in multiple parts. Our team had to write a lobby system, integrate with Shockwave's chat, and there was also a "Texas Hold'em" game we produced as well.<br/><br/>
            </v-list-item-content>
            <v-img contain max-width="200" max-height="150" src="@/assets/fuel/2009_bingo.jpg" @click="openThumbnails('bingo')"></v-img>
          </v-list-item>
          <br/>
        </v-card>
      </v-timeline-item>

      <v-timeline-item right>
        <v-card class="elevation-2">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline">Million Dollar Password</v-list-item-title>
              <v-list-item-subtitle>2009</v-list-item-subtitle>
              It's always weird to find out what game credits you'll find yourselves on MobyGames's website. Out of everything I've ever produced, this little game was one of them. I only played a small role and helped with a bit of the debugging when the project was wrapping up.<br/><br/>
              <a href="https://www.mobygames.com/person/372115/glen-heide/" target="_blank">Here's my MobyGames link so you can see for yourself</a>
            </v-list-item-content>
          </v-list-item>
          <v-container style="max-width: 540px">
            <v-card flat tile class="d-flex" elevation="4" max-width="1350" @click="openThumbnails('password')">
              <v-img v-for="(thumb, i) in thumbnailsPassword" :key="i" aspect-ratio="1" :src="thumb" style="margin: 10px"></v-img>
            </v-card>
          </v-container>
          <br/>
        </v-card>
      </v-timeline-item>

      <v-timeline-item right>
        <v-card class="elevation-2">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline">Server Quest</v-list-item-title>
              <v-list-item-subtitle>2008</v-list-item-subtitle>
              An interesting project sponsored by Microsoft to promote their TechNet service which ran from 2008 to 2013. They spared no expense as this was a rather big budget project with a fully voiced cast in a King's Quest style game. There were lots of minigames to play, and several people in the office got to contribute various nerdy jokes to the dialog.<br/><br/>
              Here's an interesting fact. Microsoft enjoyed it so much that in 2009 they got the company to work on the sequel "Server Quest 2" and produce it using their flash alternative "Silver Light". Unfortunately, most of the people that worked on the 1st one never touched the sequel, myself included.
            </v-list-item-content>
          </v-list-item>
          <v-container style="max-width: 840px">
            <v-card flat tile class="d-flex" elevation="4" max-width="1350" @click="openThumbnails('serverquest')">
              <v-img v-for="(thumb, i) in thumbnailsServerQuest" :key="i" aspect-ratio="1" :src="thumb" style="margin: 10px"></v-img>
            </v-card>
          </v-container>
          <br/>
        </v-card>
      </v-timeline-item>

      <v-timeline-item right>
        <v-card class="elevation-2">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline">Math Moves U: Joule's Jewels</v-list-item-title>
              <v-list-item-subtitle>2008</v-list-item-subtitle>
              Between 2006 and 2016, "MathMovesU" was an educational initiative created by Raytheon Company aimed at encouraging middle and high school students to engage with math and science through interactive and fun learning experiences. One of those fun experiences was a fun and goofy game called "Joule's Jewels" that I had the pleasure of working on.<br/><br/>
              The game consisted of 3 levels, and I worked on the first two. The 1st level was a top-down labrynth where the player had to find 3 pieces of a key in order to progress. If they encountered any mummies or spiders, they had to answer math questions to continue. The 2nd level was a platformer where they had to avoid tourists and crows while climbing to the top of the Eiffel Tower. The 3rd level required the player to find the right power and angle to shoot gems into a minecart.<br/><br/>
              This game also had a fun "pixel art" style to it<br/>
            </v-list-item-content>
          </v-list-item>
          <v-container style="max-width: 720px">
            <v-card flat tile class="d-flex" elevation="4" max-width="1350" @click="openThumbnails('joule')">
              <v-img v-for="(thumb, i) in thumbnailsJoulesJewels" :key="i" aspect-ratio="1" :src="thumb" style="margin: 10px"></v-img>
            </v-card>
          </v-container>
          <br/>
        </v-card>
      </v-timeline-item>

      <v-timeline-item right>
        <v-card class="elevation-2">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline">Viking Quest</v-list-item-title>
              <v-list-item-subtitle>2008</v-list-item-subtitle>
              One of my favorite projects I worked on. Viking Quest is a fictional TV show within another TV series called Entourage (produced by HBO). The gameplay was a classic beat'em up style game like double dragon or battle toads. You play as Tarvold that has to hack'n'slash his way through 4 levels. Levels 2 and 4 had bosses with unique mechanics you had to fight against.<br/><br/>
              One of the reasons this was a fun project was because having HBO as your client meant you got requests like "Can we add more blood?". The game's soundtrack was also full of viking death metal.<br/><br/>
              <a href="https://en.wikipedia.org/wiki/Viking_Quest">Viking Quest wikipedia page</a><br/>              
            </v-list-item-content>
          </v-list-item>
          <v-container style="max-width: 1370px">
            <v-card flat tile class="d-flex" elevation="4" max-width="1350" @click="openThumbnails('vikingquest')">
              <v-img v-for="(thumb, i) in thumbnailsVikingQuest" :key="i" aspect-ratio="1" :src="thumb" style="margin: 10px"></v-img>
            </v-card>
          </v-container>
          <br/>
        </v-card>
      </v-timeline-item>

      <v-timeline-item right>
        <v-card class="elevation-2">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline">McDonalds Fairies and Dragons: Fire Dragon, Lily, Sunflower</v-list-item-title>
              <v-list-item-subtitle>2008</v-list-item-subtitle>
              This was a rather ambitious project by McDonalds. The idea was that they tried creating a brand new IP called "Fairies and Dragons" which included 4 fairies and 4 dragons. Each one of these characters had a game made just for them available on CD or on the web. This means there were 8 games total. You can actually still find a lot of information about these games online, and it usually follows people saying "I use to play that when I was a kid!". I mainly worked on the Fire Dragon, Lily and Sunflower games.<br/><br/>
              <ul>
                <li>Fire Dragon was a side-scrolling shooter where you blew up castles with your fire breath.</li>
                <li>Lily had to catch falling fruit while avoiding mosquitos and jumping over ants.</li>
                <li>Sunflower likes to glide across the sky on a leaf going as far as possible, and catching as many fruits as possible.</li>
              </ul><br/>
              One interesting aspect about this project was that we didn't just make a "web" game like we normally do. At the time, Flash had the ability to publish windows executables so you could play it off of a CD. We also had to use software to make it compatible for Macs as well. There even included a complete version that contained all of the games and contained a wizard and a magic book you used to navigate. There was a couple extra mini games and 8 more games for each character in that as well.<br/><br/>
              Here's some links if you want more information about this:<br/>
              <a href="https://www.mobygames.com/game/60551/mcdonalds-fairies-and-dragons-sunflower/">MobyGames Profile</a><br/>
              <a href="https://www.myabandonware.com/game/mcdonald-s-fairies-and-dragons-sunflower-g3g">Abandonware Link where you can download and play the original games</a><br/>
            </v-list-item-content>
          </v-list-item>
          <v-container style="max-width: 1370px">
            <v-card flat tile class="d-flex" elevation="4" max-width="1350" @click="openThumbnails('fairiesdragons')">
              <v-img v-for="(thumb, i) in thumbnailsFairiesDragons" :key="i" aspect-ratio="1" :src="thumb" style="margin: 10px"></v-img>
            </v-card>
          </v-container>
          <br/>
        </v-card>
      </v-timeline-item>

      <v-timeline-item right>
        <v-card class="elevation-2">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline">Candystand: Cool Darts & Mahjongg Solitaire</v-list-item-title>
              <v-list-item-subtitle>2007</v-list-item-subtitle>
              Candystand used to be a host for hundreds of games until they rebranded in 2016. These days unfortunately, the website has all been replaced for casino and lottery style gaming. In its golden years though, I had the privelege of making 2 games for that website: Cool Darts & Mahjongg Solitaire<br/>
              Believe it or not, one of the first flash games I ever wrote was a Mahjongg game while I was working on my bachelors degree. You can see the original if you check out my "Education" section.<br/>
              There's a couple interests twists that this mahjongg game has that made it interesting. There was a global leaderboard so you could compete against other players to see who could finish each level the fastest, and if you matched the same 4 tiles in a row, you got a time bonus. If you were good enough, you could actually get enough bonuses that your score would only be 1 second (the minimum).<br/>
              Darts was a pretty simple game, but it was interesting to write 3 different game modes: Cricket, 501, and Baseball. You could also play darts with a computer AI, or you could have up to 4 players playing against each other on the same machine.<br/>
            </v-list-item-content>
          </v-list-item>
          <v-container style="max-width: 820px">
            <v-card flat tile class="d-flex" elevation="4" max-width="1350" @click="openThumbnails('candystand')">
              <v-img v-for="(thumb, i) in thumbnailsCandystand" :key="i" aspect-ratio="1" :src="thumb" style="margin: 10px"></v-img>
            </v-card>
          </v-container>
          <br/>
        </v-card>
      </v-timeline-item>

      <v-timeline-item right>
        <v-card class="elevation-2">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline">The Dresden Files: Soul Pool</v-list-item-title>
              <v-list-item-subtitle>2007</v-list-item-subtitle>
              The Dresden Files was a book series, then a short-lived TV series. We made a game about called "Soul Pool" where Dresden must face off against a demon in a game of pool in order to save the soul of a woman held captive by the demon.<br/>
              It's a pretty interesting little game. The entire game follows the official rules of pool (you have to sink stripes or solids, depending on what you sink first, 8 ball last, etc...) except that each game you and the demon each have 5 defensive and 5 offensive spells you can use to block your opponents shots.              
            </v-list-item-content>
          </v-list-item>
          <v-container style="max-width: 720px">
            <v-card flat tile class="d-flex" elevation="4" max-width="1350" @click="openThumbnails('dresden')">
              <v-img v-for="(thumb, i) in thumbnailsDresden" :key="i" aspect-ratio="1" :src="thumb" style="margin: 10px"></v-img>
            </v-card>
          </v-container>
          <br/>
        </v-card>
      </v-timeline-item>

      <v-timeline-item right>
        <v-card class="elevation-2">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline">Winx Club: Fairy Makeover</v-list-item-title>
              <v-list-item-subtitle>2006</v-list-item-subtitle>
              Out of all the game I worked on, it turns out this one is still on the internet. How exciting is that?
              <a href="https://free-games-for-kids.com/winx/winx_makeover.html" target="_blank">Click here to play!</a><br/>
              As far as dress up games go, it's pretty simple. Pick the clothes you want on what you would look like as a Winx fairy, then either save it as a background or send it your your friends (although, it looks like all those features I just mentioned don't work anymore since I'm pretty sure Fuel Industries hosted the server that did all of that).
            </v-list-item-content>
          </v-list-item>
          <v-container style="max-width: 540px">
            <v-card flat tile class="d-flex" elevation="4" max-width="1350" @click="openThumbnails('winx')">
              <v-img v-for="(thumb, i) in thumbnailsWinx" :key="i" aspect-ratio="1" :src="thumb" style="margin: 10px"></v-img>
            </v-card>
          </v-container>
          <br/>
        </v-card>
      </v-timeline-item>

      <v-timeline-item right>
        <v-card class="elevation-2">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline">Reach: In the Mouth of Mystery</v-list-item-title>
              <v-list-item-subtitle>2006</v-list-item-subtitle>
              My first big project at Fuel Industries. It was a simple puzzle game where Mr Reach had to use various Reach products to track down and find the missing Narrator. I look back at this and sometimes think that the quality was over the top. The music and art for this little game is pretty amazing.
            </v-list-item-content>
          </v-list-item>
          <v-container style="max-width: 1370px">
            <v-card flat tile class="d-flex" elevation="4" max-width="1350" @click="openThumbnails('reach')">
              <v-img v-for="(thumb, i) in thumbnailsReach" :key="i" aspect-ratio="1" :src="thumb" style="margin: 10px"></v-img>
            </v-card>
          </v-container>
          <br/>
        </v-card>
      </v-timeline-item>

      </v-timeline>
    </v-row>

    <v-dialog v-if="thumbnailDialog" v-model="thumbnailDialog" max-width="1200">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ thumbnailsTitle }}
        </v-card-title>

        <v-card-text>
          <v-carousel show-arrows-on-hover hide-delimiters height="850">
            <v-carousel-item v-for="(thumb, i) in thumbnails" :key="i">
              <v-img contain aspect-ratio="1" :src="thumb" max-height="850"></v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="thumbnailDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="videoDialog" v-model="videoDialog" max-width="800">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Ginger Snaps!
        </v-card-title>

        <v-card-text>
          <vue-core-video-player src="http://classicalgamer.com/video/2010_shrek_360.mp4"></vue-core-video-player>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="videoDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      videoDialog: false,
      thumbnailDialog: false,
      thumbnails: null,
      thumbnailsDisrespectoids1: [ require('@/assets/fuel/2009_disrespectoids1.jpg'), require('@/assets/fuel/2009_disrespectoids2.jpg'), require('@/assets/fuel/2009_disrespectoids3.jpg'), require('@/assets/fuel/2009_disrespectoids4.jpg'), require('@/assets/fuel/2009_disrespectoids5.jpg'), require('@/assets/fuel/2009_disrespectoids6.jpg'), require('@/assets/fuel/2009_disrespectoids7.jpg'), require('@/assets/fuel/2009_disrespectoids8.jpg'), require('@/assets/fuel/2009_disrespectoids9.jpg'), require('@/assets/fuel/2009_disrespectoids10.jpg'), require('@/assets/fuel/2009_disrespectoids11.jpg'), require('@/assets/fuel/2009_disrespectoids12.jpg'), require('@/assets/fuel/2009_disrespectoids13.jpg'), require('@/assets/fuel/2009_disrespectoids14.jpg'), require('@/assets/fuel/2009_disrespectoids15.jpg'), require('@/assets/fuel/2009_disrespectoids16.jpg'), ],
      thumbnailsDisrespectoids2: [ require('@/assets/fuel/2010_disrespectoids1.jpg'), require('@/assets/fuel/2010_disrespectoids2.jpg'), require('@/assets/fuel/2010_disrespectoids3.jpg'), require('@/assets/fuel/2010_disrespectoids4.jpg'), require('@/assets/fuel/2010_disrespectoids5.jpg'), require('@/assets/fuel/2010_disrespectoids6.jpg'), require('@/assets/fuel/2010_disrespectoids7.jpg'), require('@/assets/fuel/2010_disrespectoids8.jpg'), ],
      thumbnailsGingerSnaps: [ ],
      thumbnailsGXSkate: [ require('@/assets/fuel/2009_gxskate1.jpg'), require('@/assets/fuel/2009_gxskate2.jpg'), require('@/assets/fuel/2009_gxskate3.jpg'), require('@/assets/fuel/2009_gxskate4.jpg'), require('@/assets/fuel/2009_gxskate5.jpg'), ],
      thumbnailsJuku: [ require('@/assets/fuel/2009_juku1.jpg'), require('@/assets/fuel/2009_juku2.jpg'), require('@/assets/fuel/2009_juku3.jpg'), require('@/assets/fuel/2009_juku4.jpg'), ],
      thumbnailsBingo: [ require('@/assets/fuel/2009_bingo.jpg'), ],
      thumbnailsPassword: [ require('@/assets/fuel/2009_password1.jpg'), require('@/assets/fuel/2009_password2.jpg'), require('@/assets/fuel/2009_password3.jpg'), ],
      thumbnailsServerQuest: [ require('@/assets/fuel/2008_serverquest1.jpg'), require('@/assets/fuel/2008_serverquest2.jpg'), require('@/assets/fuel/2008_serverquest3.jpg'), require('@/assets/fuel/2008_serverquest4.jpg'), require('@/assets/fuel/2008_serverquest5.jpg'),  ],
      thumbnailsJoulesJewels: [ require('@/assets/fuel/2008_joule1.jpg'), require('@/assets/fuel/2008_joule2.jpg'), require('@/assets/fuel/2008_joule3.jpg'), require('@/assets/fuel/2008_joule4.jpg'), ],
      thumbnailsVikingQuest: [ require('@/assets/fuel/2008_vikingquest1.jpg'), require('@/assets/fuel/2008_vikingquest2.jpg'), require('@/assets/fuel/2008_vikingquest3.jpg'), require('@/assets/fuel/2008_vikingquest4.jpg'), require('@/assets/fuel/2008_vikingquest5.jpg'), require('@/assets/fuel/2008_vikingquest6.jpg'), require('@/assets/fuel/2008_vikingquest7.jpg'), ],
      thumbnailsFairiesDragons: [ require('@/assets/fuel/2008_fairiesanddragons1.jpg'), require('@/assets/fuel/2008_fairiesanddragons2.jpg'), require('@/assets/fuel/2008_fairiesanddragons3.jpg'), require('@/assets/fuel/2008_fairiesanddragons4.jpg'), require('@/assets/fuel/2008_fairiesanddragons5.jpg'), require('@/assets/fuel/2008_fairiesanddragons6.jpg'), require('@/assets/fuel/2008_fairiesanddragons7.jpg'), require('@/assets/fuel/2008_fairiesanddragons8.jpg'),  ],
      thumbnailsTwoCoreys: [ ],
      thumbnailsCandystand: [ require('@/assets/fuel/2007_candystand1.jpg'),  require('@/assets/fuel/2007_candystand2.jpg'), require('@/assets/fuel/2007_candystand3.jpg'), require('@/assets/fuel/2007_candystand4.jpg'), require('@/assets/fuel/2007_candystand5.jpg'), ],
      thumbnailsDresden: [ require('@/assets/fuel/2007_dresden4.jpg'),  require('@/assets/fuel/2007_dresden1.jpg'), require('@/assets/fuel/2007_dresden2.jpg'), require('@/assets/fuel/2007_dresden3.jpg'), ],
      thumbnailsWinx: [ require('@/assets/fuel/2006_winx1.jpg'), require('@/assets/fuel/2006_winx2.jpg'), require('@/assets/fuel/2006_winx3.jpg'), ],
      thumbnailsReach: [ require('@/assets/fuel/2006_reach1.jpg'), require('@/assets/fuel/2006_reach2.jpg'), require('@/assets/fuel/2006_reach3.jpg'), require('@/assets/fuel/2006_reach4.jpg'),
                         require('@/assets/fuel/2006_reach5.jpg'), require('@/assets/fuel/2006_reach6.jpg'), require('@/assets/fuel/2006_reach7.jpg'), require('@/assets/fuel/2006_reach8.jpg'), ],
    }),

    methods: {
      openThumbnails(project) {
        switch (project) {
          case 'disrespectoids1':
            this.thumbnailsTitle = 'Disrespectoids 2009';
            this.thumbnails = this.thumbnailsDisrespectoids1;
            break;
          case 'disrespectoids2':
            this.thumbnailsTitle = 'Disrespectoids 2010';
            this.thumbnails = this.thumbnailsDisrespectoids2;
            break;
          case 'gingersnaps':
            this.thumbnailsTitle = 'Shrek Happy Meal Board Game: Ginger Snaps';
            this.thumbnails = this.thumbnailsGingerSnaps;
            break;
          case 'gxskate':
            this.thumbnailsTitle = 'GX Skate: Sick Tricks Game';
            this.thumbnails = this.thumbnailsGXSkate;
            break;
          case 'jukucouture':
            this.thumbnailsTitle = 'Juku Couture - Dress Up';
            this.thumbnails = this.thumbnailsJuku;
            break;
          case 'bingo':
            this.thumbnailsTitle = 'Club Shockwave: Beach Towel Bingo';
            this.thumbnails = this.thumbnailsBingo;
            break;
          case 'password':
            this.thumbnailsTitle = 'Million Dollar Password';
            this.thumbnails = this.thumbnailsPassword;
            break;
          case 'serverquest':
            this.thumbnailsTitle = 'TechNet: Server Quest';
            this.thumbnails = this.thumbnailsServerQuest;
            break;
          case 'joule':
            this.thumbnailsTitle = 'Math Moves U: Joule\'s Jewels';
            this.thumbnails = this.thumbnailsJoulesJewels;
            break;
          case 'vikingquest':
            this.thumbnailsTitle = 'Viking Quest';
            this.thumbnails = this.thumbnailsVikingQuest;
            break;
          case 'fairiesdragons':
            this.thumbnailsTitle = 'McDonalds Fairies and Dragons: Fire Dragon';
            this.thumbnails = this.thumbnailsFairiesDragons;
            break;
          case 'candystand':
            this.thumbnailsTitle = 'Candystand: Cool Darts & Mahjongg Solitaire';
            this.thumbnails = this.thumbnailsCandystand;
            break;
          case 'dresden':
            this.thumbnailsTitle = 'Dresden Files: Soul Pool';
            this.thumbnails = this.thumbnailsDresden;
            break;
          case 'winx':
            this.thumbnailsTitle = 'Winx: Dressup';
            this.thumbnails = this.thumbnailsWinx;
            break;
          case 'reach':
            this.thumbnailsTitle = 'Reach: In the Mouse of Mystery';
            this.thumbnails = this.thumbnailsReach;
            break;
        }
        this.thumbnailDialog = true;
      }
    },
  }
</script>

<style type="text/css" scoped>
.portfolio-container {
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  background: #fff;
}
</style>
