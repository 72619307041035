<template>
  <v-container fluid fill-height class="portfolio-container">
    <v-row class="text-left">

      <v-col cols="12">
        <h1 class="display-2 font-weight-bold mb-3">
          HELIKON TECHNOLOGIES
        </h1>

        <h3 class="subheading font-weight-regular">
          This was one of my first jobs right out of school. A couple of EA veterans started a company with the pitch for a new Space Empire building MMO. What if you could take classics like Master of Orion or CIV and make it a successful online video game? Unfortunately, we may never know. The company lost its funding in 2002 and had to close, but it would've been an interesting project if it had launched.
        </h3>
      </v-col>

      <v-timeline dense>
        <v-timeline-item right>
          <v-card class="elevation-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">COSM</v-list-item-title>
                <v-list-item-subtitle>2000</v-list-item-subtitle>
                <div>
                  The concept behind COSM was fairly simple. You are the commander of your species, and as you research new technologies and explore the galaxy, you come across ancient technology that reveals your universe is only a small piece inside of a much greater universe. In terms of gameplay, what this actually meant was that your empire's technology was restricted based off of the universe level you were in. The 1st cosm starts off simple with no combat, but with each COSM that you breached, your tech levels increased allowing you to build larger empires that spanned over the next galaxy.<br/><br/>
                  Helikon was a small company, never reaching any larger than about 13 people. Myself and another artist were hired on as entry-level employees to help give a fresh look and perspective on the game they were working on. I was responsible for creating the UI system in C++, and I got to work a lot with a game engine known as NetImmerse / Gamebryo. In the old days, there wasn't that many game engines like Unity, Unreal or Godot that could create complex projects out of the box. You had to write a lot from scratch by yourself. What Gamebryo did was allow you to import 3D models from Maya/3D Studio Max and other forms of artwork and take care of all the 3D rendering for you. From that point, all you had to write was the systems that needed rendering like the UI and gameplay. It was also a fairly common engine at the time. If you look up Gamebryo's wiki, you'll see it was made for over 40-50 titles, some of which you may even recognize.<br/><br/>
                  Sadly, you won't find any information about Helikon or COSM on the internet anymore. The only way I was able to get any kind of image was via the internet archive. <br/><br/>
                  <v-list-item-subtitle>Technologies Used:</v-list-item-subtitle>
                  <div class="tech-spacing">
                    <v-btn small color="primary" href="https://en.wikipedia.org/wiki/C%2B%2B" target="_blank">C++</v-btn>
                    <v-btn small color="primary" href="https://en.wikipedia.org/wiki/Gamebryo" target="_blank">NetImmerse / Gamebryo</v-btn>
                  </div>
                </div>
              </v-list-item-content>
              <v-img contain max-width="200" max-height="150" src="@/assets/helikon/AthanorSpaceS.jpg"></v-img>
            </v-list-item>
            <br/>
          </v-card>
        </v-timeline-item>

      </v-timeline>
    </v-row>

    <v-dialog v-if="thumbnailDialog" v-model="thumbnailDialog" max-width="1200">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ thumbnailsTitle }}
        </v-card-title>

        <v-card-text>
          <v-carousel show-arrows-on-hover hide-delimiters height="850">
            <v-carousel-item v-for="(thumb, i) in thumbnails" :key="i">
              <v-img contain aspect-ratio="1" :src="thumb" max-height="850"></v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="thumbnailDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="dialog" v-model="dialog" max-width="800">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Ginger Snaps!
        </v-card-title>

        <v-card-text>
          <vue-core-video-player src="http://classicalgamer.com/video/2010_shrek_360.mp4"></vue-core-video-player>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      dialog: false,
      thumbnailDialog: false,
      thumbnails: null,
      thumbnailsMediaVault: [ require('@/assets/disney/mediavault_opentext.jpg'), require('@/assets/disney/mediaportal_search.jpg'), require('@/assets/disney/mediaportal_preview.jpg'), require('@/assets/disney/mediaportal_uploads.jpg'), require('@/assets/disney/mediaportal_upload_complete.jpg'), ],
      thumbnailsInfinity: [ require('@/assets/disney/infinity_st_all_versions.jpg'), require('@/assets/disney/infinity_st_all_platforms.jpg'), require('@/assets/disney/infinity_horcrux.jpg'), ],
      thumbnailsCap: [ require('@/assets/disney/cap_world.jpg'), require('@/assets/disney/cap_tagging.jpg'), require('@/assets/disney/cap_export.jpg'), require('@/assets/disney/cap_preview.jpg'), ],
      thumbnailsMetaplace: [ require('@/assets/disney/metaplace_portal.jpg'), require('@/assets/disney/metaplace_start.jpg'), require('@/assets/disney/metaplace_tool.jpg'), require('@/assets/disney/metaplace_lua.jpg'), require('@/assets/disney/metaplace_myvineyard.jpg'), require('@/assets/disney/metaplace_deeprealms.jpg'), require('@/assets/disney/metaplace_gnometown.jpg'), ],
    }),

    methods: {
      openThumbnails(project) {
        switch (project) {
          case 'mediavault':
            this.thumbnailsTitle = 'Media Vault';
            this.thumbnails = this.thumbnailsMediaVault;
            break;
          case 'infinity':
            this.thumbnailsTitle = 'Disney Infinity';
            this.thumbnails = this.thumbnailsInfinity;
            break;
          case 'cap':
            this.thumbnailsTitle = 'Club Penguin - Centralized Asset Pipeline';
            this.thumbnails = this.thumbnailsCap;
            break;
          case 'metaplace':
            this.thumbnailsTitle = 'Metaplace';
            this.thumbnails = this.thumbnailsMetaplace;
            break;
        }
        this.thumbnailDialog = true;
      }
    },
  }
</script>

<style type="text/css" scoped>
.portfolio-container {
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  background: #fff;
}

.tech-spacing > a {
  margin: 5px;
}
</style>
