<template>
  <v-container fluid fill-height class="portfolio-container">
    <v-row class="text-left">

      <v-col cols="12">
        <h1 class="display-2 font-weight-bold mb-3">
          EDUCATION
        </h1>

        <h3 class="subheading font-weight-regular">
          Getting to work for Disney was an incredible experience. I got to live in another country in a city that's sunny 99% of the time, I got lots of "Disney perks" where I could go to Disneyland & Disneyworld for free, and I got to work for a world-class company.<p/>
          When I first started, I worked for a small studio recently acquired by Disney called Metaplace. Metaplace was basically a tool that allowed you to make MMO-style games fast & easy. My job was to enhance the tool so that teams making the games would have an easier time.<p/>
          After a few years, Disney moved away from making games in-house and decided to license them out instead. This meant our office had to shift our priorities if we wanted to keep working. Thus was born <b>Flex-Force</b>. A small studio of about 20 employees that could act as in-house contractors working for any division within Disney if a team needed a senior-level engineer quickly.
        </h3>
      </v-col>

      <v-timeline dense>
        <v-timeline-item right>
          <v-card class="elevation-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">British Columbia Institute of Technology (2004-2006)</v-list-item-title>
                <v-list-item-subtitle>Bachelor, Computer Systems Technology</v-list-item-subtitle>
                <div>
                  One of the things at the time that sets BCIT apart from other schools was that they provided an "accelerated" program. As the sales pitch goes, would you rather spend 4 more years in school earning your Bachelors, or cram everything into 15 months instead. If you choose the latter, there's only 2 weeks worth of breaks (christmas & summer). Apart from that, school basically becomes a full time job. Probably even moreso with all the homework and projects you have to do.<br/><br/>
                  When I took this course, I had 2 options: <b>Database</b> or <b>Network Security</b>. I went with security because it sounded more interesting. We learned some pretty interesting things like how to give emails military-grade encryption, hide files within JPGs, and access a person's computer simply because of the software that was running on it. This program had one of the most interesting final projects I've ever had a chance to participate in.<br/><br/>
                  For the final project, the class was divided into 5 teams. Each team finds some hackable software (and proves it can be hosted & hacked themselves) and hosts it through a single IP (usually at one team member's house). At Friday 6pm, you share that IP with the other teams and everyone has 48 hours to hack every service on every other team. The victory condition for hacking a service was to find the "secret.txt" file on that service's computer. For that project, I actually found some FTP software for the other students to hack into. If hacked properly, I wouldn't even notice someone was in the machine. If the hack failed, the FTP server would actually crash and I'd have to restart it for the other students.<br/><br/>
                  Even though I got back into the groove of software development and learned a lot about network security, the thing that honestly landed me the job at <a @click="navigate('portfolio-fuelindustries')"><u>Fuel Industries</u></a> were the small projects I wrote in Flash. For one of our courses (which was basically an "English Literature" class), the teacher asked us to tie together one of the stories we read with some of the tech we were learning. Myself and one other classmate worked on a <a href="https://bladerunner.fandom.com/wiki/Voight-Kampff_test" target="_blank">Voight-Kampff</a> simulator and wrote it in Flash, which was a tool I heard about that lets you write software faster. Our original plan was to write it for Windows in C++. From there, I joined a small company for our 6 months of work experience (part of the Bachelors Degree requirement) and wrote an additional 3 flash games for them. All that was essentially resume building for my job to come.<br/><br/>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-container style="max-width: 1370px">
              <v-card flat tile class="d-flex" elevation="4" max-width="1350" @click="openThumbnails('mediavault')">
                <v-img v-for="(thumb, i) in thumbnailsBachelors" :key="i" aspect-ratio="1" :src="thumb" style="margin: 10px"></v-img>
              </v-card>
            </v-container>
            <br/>
          </v-card>
        </v-timeline-item>

        <v-timeline-item right>
          <v-card class="elevation-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">British Columbia Institute of Technology (1998-2000)</v-list-item-title>
                <v-list-item-subtitle>Diploma, Computer Systems Technology</v-list-item-subtitle>
                <div>
                  Before enrolling at BCIT, the only things I knew about programming were from video games, <a href="https://en.wikipedia.org/wiki/Compute!%27s_Gazette" target="_blank">Compute!'s Gazette</a> magazines, some library books, and 2 computer courses at highschool that taught the very basics. After enrolling, I dove write into everything I needed to know. It included advanced math, java, visual scripting, and a variety of programming topics that would make me the engineer I am today.<br/><br/>
                  As I was growing up, I'd make the occasional video game here and there. Clones of scorched earth or bomberman usually. However after I took the "Technical Windows Programming" course in 1999, I was able to read and understand how to use DirectX. From there, I built one of my best and earliest games "Skies of Fire". A side-scrolling shooter with lots of enemies and explosions on the screen (hence, the sky was filled with fireballs all the time).
                  <br/><br/>
                </div>
              </v-list-item-content>
            </v-list-item>
            <br/>
          </v-card>
        </v-timeline-item>

      </v-timeline>
    </v-row>

    <v-dialog v-if="thumbnailDialog" v-model="thumbnailDialog" max-width="600">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ thumbnailsTitle }}
        </v-card-title>

        <v-card-text>
          <v-carousel show-arrows-on-hover hide-delimiters height="450">
            <v-carousel-item v-for="(thumb, i) in thumbnails" :key="i">
              <v-img contain aspect-ratio="1" :src="thumb" max-height="450"></v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="thumbnailDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    thumbnailDialog: false,
    thumbnails: null,
    thumbnailsBachelors: [ require('@/assets/ancient/ancient-voight-kampff.jpg'), require('@/assets/ancient/practicum_hockey1.jpg'), require('@/assets/ancient/practicum_hockey2.jpg'), require('@/assets/ancient/practicum_overtime.jpg'), require('@/assets/ancient/practicum_mahjongg.jpg'), require('@/assets/ancient/practicum_basketball.jpg'), ],
    thumbnailsInfinity: [ require('@/assets/disney/infinity_st_all_versions.jpg'), require('@/assets/disney/infinity_st_all_platforms.jpg'), require('@/assets/disney/infinity_horcrux.jpg'), ],
  }),

  methods: {
    navigate (target) {
      this.$emit('navigate', target)
    },

    openThumbnails(project) {
      switch (project) {
        case 'mediavault':
          this.thumbnailsTitle = 'Media Vault';
          this.thumbnails = this.thumbnailsBachelors;
          break;
        case 'infinity':
          this.thumbnailsTitle = 'Disney Infinity';
          this.thumbnails = this.thumbnailsInfinity;
          break;
      }
      this.thumbnailDialog = true;
    }
  },
}
</script>

<style type="text/css" scoped>
.portfolio-container {
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  background: #fff;
}

.tech-spacing > a {
  margin: 5px;
}
</style>
