<template>
  <v-container fluid fill-height class="portfolio-container">
    <v-row class="text-left">

      <v-col cols="12">
        <h1 class="display-2 font-weight-bold mb-3">
          CONTACT ME
        </h1>

        <h4 class="subheading font-weight-regular">
          If you're interested in contacting me about opportunities in Software Engineering for Video Games, LinkedIn is the best method to reach me.
        </h4>

        <p/>

        LinkedIn:
        <v-btn href="https://www.linkedin.com/in/glen-heide/" target="_blank">
          <v-icon>mdi-linkedin</v-icon> glen.heide
        </v-btn>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
    }),
  }
</script>

<style type="text/css" scoped>
.portfolio-container {
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  background: #fff;
}
</style>
