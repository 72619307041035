<template>
  <v-container fluid class="main-container">
    <v-row class="text-center">

      <v-col cols="12">
        <h1 class="display-2 font-weight-bold mb-3 shadow-text">
          Hello, you found my website!
        </h1>

        <h3 class="subheading font-weight-regular shadow-text">
          I'm Glen Heide, a Software Engineer with over 20 years of professional experience. My expertise spans Client, UI, Tools, Gameplay, and Web engineering, with solid full-stack capabilities using the latest web technologies and Node.js backends. In short, I'm a generalist programmer.<p/>
          My journey into programming began in the 80s when I was just a kid, tinkering with the Commodore 64. It was my dad who initially sparked my interest, subscribing to the well-known "Compute!'s Gazette" magazine. Each issue was filled with tech articles and games coded in either BASIC or machine language, which fueled my early curiosity.<p/>
          Although I dabbled with computers in high school, it wasn't until I attended BCIT, a technical institute, that I gained a deep understanding of programming and engineering. There, 90% of my coursework focused on various aspects of software development.<p/>
          In my career, I've had the privilege of working with four different companies involved in gaming:<br/>
          • Helikon Technologies: A startup aiming to create a space empire MMO.<br/>
          • Fuel Industries: Developers of hundreds of Flash games.<br/>
          • Disney Interactive: Which briefly ventured into the gaming market in the early 2010s.<br/>
          • Playable Worlds: Currently developing a new sandbox MMO called "Stars Reach".<p/>
          Explore my Portfolio to see the major projects I've worked on and the kind of work I've done over the years.
        </h3>
      </v-col>

      <v-col cols="12">
        <v-btn @click="navigate('portfolio')">
          PORTFOLIO
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
  }),

  methods: {
    navigate (target) {
      this.$emit('navigate', target)
    },
  },
}
</script>

<style type="text/css" scoped>
.main-container {
  padding-top: 100px;
  padding-left: 100px;
  padding-right: 100px;
}
</style>

<style type="text/css">
.shadow-text {
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0,0,0,.6);
}
</style>
