<template>
  <v-container fluid fill-height class="portfolio-container">
    <v-row class="text-left">

      <v-col cols="12">
        <h1 class="display-2 font-weight-bold mb-3">
          PORTFOLIO
        </h1>

        <h3 class="font-weight-bold subheading font-weight-regular">
          Believe it or not, I first started computer programming when I was 5 years old. My parents bought a Commodore 64 and I was obsessed with it. I would write programs out of the Compute Gazette magazine we'd get every month. It wasn't until the year 2000 did my career in Software Engineering finally take off.
        </h3>
      </v-col>

      <v-timeline dense>
        <v-timeline-item v-for="item in portfolioItems" :key="item.title" right>
          <v-card class="elevation-2" @click="navigate(item.link)">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">{{ item.title }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.years }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-spacer/>
              <v-img v-if="item.logo" :max-width="item.logoWidth || 48" max-height="48" contain :src="item.logo"></v-img>
            </v-list-item>
            <v-card-text>
              <span v-html="item.text"></span>
            </v-card-text>
          </v-card>
        </v-timeline-item>

      </v-timeline>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    portfolioItems: [
      { link: 'portfolio-playableworlds', title: 'Playable Worlds', years: '2020 - 2023', logo: require("@/assets/pw-logo.png"),
        text: 'After working for a large corporation like Disney for a decade, I decided I wanted to switch things up a bit and get back to my roots of working for startups. Several of my co-workers, including one of my old bosses Raph Koster were working for a company called Playable Worlds on a new game called Stars Reach. It was fun to work for a smaller sized company on one of Raph\'s visions. Also, this all happened at the height of COVID, so the company was run completely remote. A very interesting change from all the in-office works throughout the years.'
      },
      { link: 'portfolio-disney', title: 'Disney Interactive', years: '2010 - 2020', logo: require("@/assets/disney-logo.png"),
        text: 'Working for Disney was a stroke of luck. When I was offered the job, I was being hired by a company called "Metaplace", which was being acquired by "Playdom", which was being acquired by "Disney". When I first started, I worked as a tools developer for the Metaplace platform helping with games such as "My Vineyard", "Gnome Town", "Deep Realms" and "Dragon Town". When support for Metaplace dropped, our office moved to more of a "Flex Force" division. We were a group of 20 senior developers that basically worked as contractors within Disney. From there, I worked on titles such as Club Penguin and Disney Infinity. As Disney moved away from games, my role changed to that of full-stack web developer.'
      },
      { link: 'portfolio-fuelindustries', title: 'Fuel Industries', years: '2006 - 2010', logo: require("@/assets/fuel-logo.png"),
        text: 'Fuel Industries had a pretty intense work environment. Projects would come quickly, and due dates were very often weeks away from when you first got them. While quite stressful, you learn how to work fast, and in the end I ended up making over 30 different games for the web in that time. Probably closer to 40 if you don\'t count the projects that never made it.'},
      { link: 'portfolio-education', title: 'BCIT Bachelors Degree Program in Computer Systems', years: '2004 - 2006', logo: require("@/assets/bcit-logo.jpg"),
        text: 'After a couple years with no luck in the job hunt, I decided to go back to school to advance my education further. BCIT offered something known as an "accelerrated" program. Whereas normal universities teach bachelor degrees in 4 years, they compressed it into 15 months. The main focus of study was in Network Security. The final project of that class was to set up servers and hack your classmates.'},
      { link: 'portfolio-helikon', title: 'Helikon Technologies', years: '2000 - 2002', logo: require("@/assets/helikon-logo.jpg"), logoWidth: 169,
        text: 'Helikon was a small startup game company with a pretty ambitious project. The game we were working on was called "COSM". It was a space-empire building MMO where the universe was divided into layers. When a player leveled up, their empire would breach a layer (or Cosm) and advance to new galaxies and technologies. Unfortunately due to the early 2000s recession, this project never made it.'},
      { link: 'portfolio-education', title: 'BCIT Diploma in Computer Systems Technology', years: '1998 - 2000', logo: require("@/assets/bcit-logo.jpg"),
        text: 'In the pre-internet days, it was hard to come by information on how computers work. It was pretty exciting when I got accepted into BCIT. I got to learn multiple computer languages, best programming practices, and I managed to make a pretty good game using DirectX for one of my final projects.'},
    ]
  }),

  methods: {
    navigate (target) {
      this.$emit('navigate', target)
    },
  },
}
</script>

<style type="text/css" scoped>
.portfolio-container {
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  background: #fff;
}
</style>

<style type="text/css">

</style>
