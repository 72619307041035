<template>
  <v-container fluid fill-height class="portfolio-container">
    <v-row class="text-left">

      <v-col cols="12">
        <h1 class="display-2 font-weight-bold mb-3">
          PLAYABLE WORLDS
        </h1>

        <h3 class="subheading font-weight-regular">
          One of the main reasons I joined <a href="https://www.playableworlds.com/" target="_blank">Playable Worlds</a> was to reconnect with my roots and work for a smaller company. Many of my former colleagues from Disney also found their way to Playable Worlds, making it a familiar and exciting environment.<p/>
          I spent three years there as a Tools Developer for the game <a href="https://starsreach.com/" target="_blank">Stars Reach</a>, an ambitious project with many moving parts. While it's still in early development (as of August 2024), I'm hopeful we'll see more of it in the future.<p/>
          My primary focus was on two tools, which you can explore at the bottom of this page.
        </h3>
      </v-col>

      <v-timeline dense>
        <v-timeline-item right>
          <v-card class="elevation-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">Stars Reach</v-list-item-title>
                <v-list-item-subtitle>2020-2023</v-list-item-subtitle>
                <div>
                  Brought to you by the designer of Ultima Online and Star Wars Galaxies, STARS REACH is all about fulfilling the promise of what online worlds can be.<p/>
                  It's a single shardless galaxy full of living planets to explore, settle, and rule with your friends. Thousands of planets and space zones, each persistent whether you're online or not, all connected into a single galactic online multiplayer game.<p/>
                  It's a massively multiplayer sandbox RPG with a deep skill tree horizontal progression system, dozens of professions, and no classes, gender locks, or annoying rails. If you decide you want to change your build, just let some skills fall out of practice and go learn something else. It's built for immersing you into an alternate world of adventure.<p/>
                  This huge galaxy won't get in the way of having instant fun, though. Download your brainwaves into a clone body close to your friends. Have fun in a five-minute session – or spend hours decorating your house. It's a modern online game that respects your time and preferred ways to play.<br/><br/>
                  <i>(Description pulled directly from <a href="https://starsreach.com/overview/" target="_blank">The Stars Reach's website</a>)</i><p/>
                  <a href="https://www.youtube.com/@playstarsreach" target="_blank">Stars Reach also has a Youtube page</a><br/><br/>
                  <v-list-item-subtitle>Technologies Used:</v-list-item-subtitle>
                  <div class="tech-spacing">
                    <v-btn small color="primary" href="https://unity.com/" target="_blank">Unity</v-btn>
                    <v-btn small color="primary" href="https://nodejs.org/en/" target="_blank">NodeJS</v-btn>
                    <v-btn small color="primary" href="https://www.typescriptlang.org/" target="_blank">Typescript</v-btn>
                  </div>
                </div>
              </v-list-item-content>
              <v-img contain max-width="376" max-height="275" src="@/assets/playableworlds/starsreach.jpg"></v-img>
            </v-list-item>
            <br/>
          </v-card>
        </v-timeline-item>

        <v-timeline-item right>
          <v-card class="elevation-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">World-Proc-Gen</v-list-item-title>
                <v-list-item-subtitle></v-list-item-subtitle>
                <div>
                  The World Procedural Generator (World Proc Gen) was a tool designed for world creation using a series of algorithms. Think of it like the world generation you've seen in Minecraft or No Man's Sky. Stars Reach operates in a similar way. You start with a vision of what a section of the map should look like, whether it includes lakes, cliffs, rugged terrain, or caves. A world file is created based on your vision, and procedural algorithms generate the desired results.<p/>
                  For example, if you wanted to build a world with basic hill structures, you'd start by creating a 2D map using <a href="https://www.google.com/search?q=perlin+noise" target="_blank">Perlin Noise</a>. This 2D map is then converted into a 3D voxel map, with parameters defining the materials and height of the terrain. At its core, that's the most basic approach. World Proc Gen included over a hundred algorithms, enabling designers to create their envisioned worlds. Since the design was algorithmic, every world generated with a new random seed offered billions of unique possibilities.<br/><br/>
                  <v-list-item-subtitle>Technologies Used:</v-list-item-subtitle>
                  <div class="tech-spacing">
                    <v-btn small color="primary" href="https://unity.com/" target="_blank">Unity</v-btn>
                    <v-btn small color="primary" href="https://www.google.com/search?q=procedural+texture+generation+algorithms&udm=2" target="_blank">Procedurally Generated Algorithms</v-btn>
                    <v-btn small color="primary" href="https://www.google.com/search?q=voxel+algorithms&udm=2" target="_blank">Voxel Algorithms</v-btn>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
            <br/>
          </v-card>
        </v-timeline-item>

        <v-timeline-item right>
          <v-card class="elevation-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">Object Type Editor</v-list-item-title>
                <v-list-item-subtitle></v-list-item-subtitle>
                <div>
                  Stars Reach isn't a game built in traditional ways. If you're familiar with <a href="https://www.raphkoster.com/" target="_blank">Raph Koster's</a> work, you know his goal has always been to create a game that is as dynamic as possible. In Stars Reach, every object is a combination of components, each defined in script. These components might include "Physical" (having a physics collider), "Renderable" (a 3D model with animations), or "Pathable" (capable of movement), among others. Each component has default behaviors, but to create objects like trees, rocks, or monsters, you would use the Object Type Editor.<p/>
                  The first function of the Object Type Editor is to scan game scripts and identify the components created by the gameplay team. The tool's user can then create a new object template by combining the desired components. For instance, a tree might need to be "Physical," "Renderable," "Growable," "Reproductive" (trees can produce seeds), and "Harvestable." Designers can then generate as many trees as needed, each with the properties of the selected components. Once the design is finalized, it can be easily spawned in-game to see how the new asset performs.<p/>
                  Unlike many other games, where new assets typically require a rebuild or redeploy, Stars Reach, and the broader Playable Worlds platform, allows assets and scripts to be streamed directly into the game, eliminating the need for restarting servers. In theory, new trees, characters, environments, etc... can be streamed to the user when they are released while the user is still playing the game.<br/><br/>
                  <v-list-item-subtitle>Technologies Used:</v-list-item-subtitle>
                  <div class="tech-spacing">
                    <v-btn small color="primary" href="https://unity.com/" target="_blank">Unity</v-btn>
                    <v-btn small color="primary" href="https://www.typescriptlang.org/" target="_blank">Typescript</v-btn>
                    <v-btn small color="primary" href="https://typedoc.org/" target="_blank">Typedoc</v-btn>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
            <br/>
          </v-card>
        </v-timeline-item>

      </v-timeline>
    </v-row>

    <v-dialog v-if="thumbnailDialog" v-model="thumbnailDialog" max-width="1200">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ thumbnailsTitle }}
        </v-card-title>

        <v-card-text>
          <v-carousel show-arrows-on-hover hide-delimiters height="850">
            <v-carousel-item v-for="(thumb, i) in thumbnails" :key="i">
              <v-img contain aspect-ratio="1" :src="thumb" max-height="850"></v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="thumbnailDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="dialog" v-model="dialog" max-width="800">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Ginger Snaps!
        </v-card-title>

        <v-card-text>
          <vue-core-video-player src="http://classicalgamer.com/video/2010_shrek_360.mp4"></vue-core-video-player>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      dialog: false,
      thumbnailDialog: false,
      thumbnails: null,
      thumbnailsMediaVault: [ require('@/assets/disney/mediavault_opentext.jpg'), require('@/assets/disney/mediaportal_search.jpg'), require('@/assets/disney/mediaportal_preview.jpg'), require('@/assets/disney/mediaportal_uploads.jpg'), require('@/assets/disney/mediaportal_upload_complete.jpg'), ],
      thumbnailsInfinity: [ require('@/assets/disney/infinity_st_all_versions.jpg'), require('@/assets/disney/infinity_st_all_platforms.jpg'), require('@/assets/disney/infinity_horcrux.jpg'), ],
      thumbnailsCap: [ require('@/assets/disney/cap_world.jpg'), require('@/assets/disney/cap_tagging.jpg'), require('@/assets/disney/cap_export.jpg'), require('@/assets/disney/cap_preview.jpg'), ],
      thumbnailsMetaplace: [ require('@/assets/disney/metaplace_portal.jpg'), require('@/assets/disney/metaplace_start.jpg'), require('@/assets/disney/metaplace_tool.jpg'), require('@/assets/disney/metaplace_lua.jpg'), require('@/assets/disney/metaplace_myvineyard.jpg'), require('@/assets/disney/metaplace_deeprealms.jpg'), require('@/assets/disney/metaplace_gnometown.jpg'), ],
    }),

    methods: {
      openThumbnails(project) {
        switch (project) {
          case 'mediavault':
            this.thumbnailsTitle = 'Media Vault';
            this.thumbnails = this.thumbnailsMediaVault;
            break;
          case 'infinity':
            this.thumbnailsTitle = 'Disney Infinity';
            this.thumbnails = this.thumbnailsInfinity;
            break;
          case 'cap':
            this.thumbnailsTitle = 'Club Penguin - Centralized Asset Pipeline';
            this.thumbnails = this.thumbnailsCap;
            break;
          case 'metaplace':
            this.thumbnailsTitle = 'Metaplace';
            this.thumbnails = this.thumbnailsMetaplace;
            break;
        }
        this.thumbnailDialog = true;
      }
    },
  }
</script>

<style type="text/css" scoped>
.portfolio-container {
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  background: #fff;
}

.tech-spacing > a {
  margin: 5px;
}
</style>
