<template>
  <v-app class="background-image">
    <!-- MOBILE DISPLAY -->
    <v-app-bar class="hidden-md-and-up" :app="isMobile" dark color="rgba(25, 118, 210, 0.5)" :extended="extended" extension-height="504px">
      <div @click="navigate('main-page')" style="cursor: pointer;">
        <v-avatar>
          <v-img src="@/assets/profile.jpg"/>
        </v-avatar>
      </div>
      <v-spacer/>
      <v-toolbar-title @click="navigate('main-page')" style="cursor: pointer;">Glen Heide</v-toolbar-title>
      <v-spacer/>
      <v-btn fab text href="https://www.linkedin.com/in/glen-heide/" target="_blank">
        <v-icon>mdi-linkedin</v-icon>
      </v-btn>
      <v-app-bar-nav-icon @click="extended=!extended"></v-app-bar-nav-icon>

      <template v-slot:extension v-if="extended">
        <v-spacer/>
        <v-list-item-group v-model="selectedPage" mandatory class="text-right">
          <v-list-item link :value="'portfolio'" @click="extended=false">
            <v-list-item-content>
              <v-list-item-title>Portfolio</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item v-for="item in portfolios" :key="item.title" link :value="item.id" @click="extended=false">
            <v-list-item-content class="portfolio-indent-right">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item dense disabled hidden :value="'main-page'" style="visibility: hidden;"/>

          <v-list-item dense disabled hidden :value="'extra'" style="visibility: hidden;"/>

          <v-list-item link :value="'linkedin'" @click="clickUrl('https://www.linkedin.com/in/glen-heide/')">
            <v-list-item-icon>
              <v-img width="24" height="24" src="@/assets/linkedin-icon.png"/>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>linkedin.com/in/glen-heide/</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item link :value="'glens-games'" @click="clickUrl('https://www.linkedin.com/in/glen-heide/')">
            <v-list-item-icon>
              <v-img width="24" height="24" src="@/assets/classic-icon.png"/>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>youtube.com/@glens-games</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :value="'classical-games'" @click="extended=false">
            <v-list-item-content>
              <v-list-item-title>Classical Games!</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-img width="24" height="24" src="@/assets/classic-icon.png"/>
            </v-list-item-icon>
          </v-list-item> -->

        </v-list-item-group>
      </template>
    </v-app-bar>

    <!-- DESKTOP DISPLAY -->
    <v-navigation-drawer class="hidden-sm-and-down" color="rgba(25, 118, 210, 0.5)" fixed dark :app="!isMobile" width="350">
      <v-list nav class="py-2">
        <v-list-item-content @click="navigate('main-page')" style="cursor: pointer;">
          <v-avatar size="200" class="d-block">
            <v-img src="@/assets/profile.jpg"/>
          </v-avatar>
        </v-list-item-content>
        <v-list-item dense @click="navigate('main-page')" style="cursor: pointer;">
          <div class="my-name">Glen Heide</div>
        </v-list-item>
        <v-list-item dense @click="navigate('main-page')" style="cursor: pointer;">
          <div class="my-title">Software Engineer<br/>(Tools, Client, UI, Gameplay, Web)</div>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item-group v-model="selectedPage" mandatory>
          <v-list-item link :value="'portfolio'">
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Portfolio</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-for="item in portfolios" :key="item.title" link :value="item.id">
            <v-list-item-content class="portfolio-indent">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item dense disabled hidden :value="'main-page'" style="visibility: hidden;"/>

          <v-list-item link :value="'linkedin'" @click="clickUrl('https://www.linkedin.com/in/glen-heide/')">
            <v-list-item-icon>
              <v-img width="24" height="24" src="@/assets/linkedin-icon.png"/>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>linkedin.com/in/glen-heide/</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item link :value="'glens-games'" @click="clickUrl('https://www.linkedin.com/in/glen-heide/')">
            <v-list-item-icon>
              <v-img width="24" height="24" src="@/assets/classic-icon.png"/>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>youtube.com/@glens-games</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :value="'classical-games'">
            <v-list-item-icon>
              <v-img width="24" height="24" src="@/assets/classic-icon.png"/>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Classical Games!</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main class="main-background">
      <portfolio v-if="selectedPage == 'portfolio'" @navigate="navigate"/>
      <portfolio-playableworlds v-else-if="selectedPage == 'portfolio-playableworlds'" @navigate="navigate"/>
      <portfolio-disney v-else-if="selectedPage == 'portfolio-disney'" @navigate="navigate"/>
      <portfolio-fuelindustries v-else-if="selectedPage == 'portfolio-fuelindustries'" @navigate="navigate"/>
      <portfolio-helikon v-else-if="selectedPage == 'portfolio-helikon'" @navigate="navigate"/>
      <portfolio-education v-else-if="selectedPage == 'portfolio-education'" @navigate="navigate"/>
      <about-me v-else-if="selectedPage == 'about-me'" @navigate="navigate"/>
      <resume v-else-if="selectedPage == 'resume'" @navigate="navigate"/>
      <contact v-else-if="selectedPage == 'contact'" @navigate="navigate"/>
      <youtube-glens-games v-else-if="selectedPage == 'glens-games'" @navigate="navigate"/>
      <classical-games v-else-if="selectedPage == 'classical-games'" @navigate="navigate"/>
      <main-page v-else @navigate="navigate"/>
    </v-main>
  </v-app>
</template>

<script>
import MainPage from './components/main-page';
import Portfolio from './components/portfolio';
import PortfolioPlayableworlds from './components/portfolio-playableworlds';
import PortfolioDisney from './components/portfolio-disney';
import PortfolioFuelindustries from './components/portfolio-fuelindustries';
import PortfolioHelikon from './components/portfolio-helikon';
import PortfolioEducation from './components/portfolio-education';
import AboutMe from './components/about-me';
import Resume from './components/resume';
import Contact from './components/contact';
import YoutubeGlensGames from './components/youtube-glens-games';
import ClassicalGames from './components/classical-games';

export default {
  name: 'App',

  components: {
    MainPage,
    Portfolio,
    PortfolioPlayableworlds,
    PortfolioDisney,
    PortfolioFuelindustries,
    PortfolioHelikon,
    PortfolioEducation,
    AboutMe,
    Resume,
    Contact,
    YoutubeGlensGames,
    ClassicalGames,
  },

  data: () => ({
    extended: false,
    selectedPage: 'main-page',
    isMobile: false,
    portfolios: [
      { title: 'Playable Worlds (2020-Present)', id: 'portfolio-playableworlds' },
      { title: 'Disney (2010-2020)', id: 'portfolio-disney' },
      { title: 'Fuel Industries (2006-2010)', id: 'portfolio-fuelindustries' },
      { title: 'Helikon Technologies (2000-2002)', id: 'portfolio-helikon' },
      { title: 'Education', id: 'portfolio-education' },
    ],
  }),

  mounted () {
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true });
  },

  methods: {
    navigate (target) {
      this.selectedPage = target;
      this.extended = false;
      this.$router.push({ path: target })
    },
    clickUrl (url) {
      window.open(url, '_blank');
    },
    onResize () {
      this.isMobile = window.innerWidth < 960;
    },
  },
};
</script>

<style type="text/css">
.main-background:before {
  position: fixed;
  top: 0;
  left: 0;
  content: " ";
  width: 100%;
  height: 100%;
  background: url(assets/vancouver_fill.jpg) top center no-repeat #bababa;
  background-size: cover;
}

.v-application .primary {
  background-color: rgba(145,13,13,.8) !important;
}

.my-name {
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
}

.my-title {
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
}

.portfolio-indent {
  margin-left: 2em;
}

.portfolio-indent-right {
  margin-right: 2em;
}
</style>